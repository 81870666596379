import { render, staticRenderFns } from "./BenefitCardForm.vue?vue&type=template&id=51d17a5c&scoped=true&"
import script from "./BenefitCardForm.vue?vue&type=script&lang=js&"
export * from "./BenefitCardForm.vue?vue&type=script&lang=js&"
import style0 from "./BenefitCardForm.vue?vue&type=style&index=0&lang=css&"
import style1 from "./BenefitCardForm.vue?vue&type=style&index=1&id=51d17a5c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51d17a5c",
  null
  
)

export default component.exports