<template>
  <div>
    <!-- Page Header Start -->
    <div class="page-title-header">
      <div class="page-header">
        <b-breadcrumb class="m-0">
          <b-breadcrumb-item
            :to="{
              name: 'DashboardHome',
            }"
          >
            <i class="fa fa-home"></i>
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            行銷應用中心
          </b-breadcrumb-item>

          <b-breadcrumb-item :to="{ name: 'BenefitCardList' }">
            權益卡列表
          </b-breadcrumb-item>

          <b-breadcrumb-item active
            >{{
              isEditing ? '編輯權益卡' : '新增權益卡'
            }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <!-- Page Header End -->

    <!-- Form Start -->
    <div class="email-wrapper wrapper">
      <div class="row bg-white">
        <div class="col-md-2 mail-sidebar pt-3">
          <div class="menu-bar">
            <ul class="menu-items">
              <li :class="{ active: step == '' }" @click="goToStep('')">
                <a>基本設定</a>
              </li>
              <li :class="{ active: step == '#content'}" @click="goToStep('#content')">
                <a>內容設定</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-10 main-view">
          <b-overlay :show="showLoading">
            <b-card>
              <!-- 基本資料 section start -->
              <section v-if="step == ''">
                <!-- 欄位部分 Start -->
                <div class="row d-flex mb-4 mb-xl-2">
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">{{ isEditing ? '編輯權益卡' : '新增權益卡' }}</h4>
                  <div class="col-12 col-xl-8">
                    <validation-error-alert
                      v-if="validationErrors"
                      :errors="validationErrors"
                    ></validation-error-alert>
                    <!-- 名稱 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="*名稱欄位"
                    >
                      <b-form-input
                        class="mb-2 mr-sm-2 mb-sm-0"
                        type="text"
                        :state="v$.benefitCard.title.$error ? false: null"
                        v-model="benefitCard.title"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="!v$.benefitCard.title.$error">
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- 名稱 End -->
                    <!-- 是否開放 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="是否開放"
                    >
                      <b-form-checkbox v-model="benefitCard.is_enabled" :value="1" :unchecked-value="0">開放</b-form-checkbox>
                    </b-form-group>
                    <!-- 是否開放 End -->
                    <!-- 開始時間 start -->
                    <b-form-group
                        label-cols="12"
                        label-cols-lg="2"
                        label-size="sm"
                        label="開始時間"
                    >
                      <template>
                        <AppDatePicker
                            v-model="benefitCard.start_at"
                            placeholder="開始時間"
                        />
                      </template>
                    </b-form-group>
                    <!-- 開始時間 end -->
                    <!-- 結束時間 start -->
                    <b-form-group
                        label-cols="12"
                        label-cols-lg="2"
                        label-size="sm"
                        label="結束時間"
                    >
                      <template>
                        <AppDatePicker
                            v-model="benefitCard.end_at"
                            placeholder="結束時間"
                        />
                      </template>
                    </b-form-group>
                    <!-- 結束時間 end -->
                    <!-- 順序 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="*順序"
                    >
                      <b-form-input
                        class="mb-2 mr-sm-2 mb-sm-0"
                        type="text"
                        :state="v$.benefitCard.order.$error ? false: null"
                        v-model="benefitCard.order"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="!v$.benefitCard.order.$error">
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- 名稱 End -->
                  </div>
                </div>
                <!-- 欄位部分 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 基本資料 section end -->

              <!-- 內容設定 section start -->
              <section v-if="step == '#content'">
                <div class="row d-flex mb-4 mb-xl-2">
                  <h4 class="col-12 col-xl-12 mb-3 font-weight-bold">內容設定</h4>
                  <div class="col-12 col-xl-8">
                    <!-- config設定 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="內容設定"
                    >
                      <JsonEditor
                        height="128px"
                        v-model="benefitCard.config"
                        ref="statusFilterMapping"
                      />
                      <b-form-invalid-feedback>
                        {{ presentValidationErrors('benefitCard.config') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- config設定 End -->
                  </div>
                </div>
                <!-- 欄位部分 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 內容設定 section end -->
            </b-card>
          </b-overlay>
        </div>
      </div>
    </div>
    <!-- Form End -->

  </div>
</template>

<style>
.ckeditor-container {
  height: 400px; /* 自定義高度 */
}

.ckeditor-container .ck-editor__editable {
  min-height: 400px; /* 確保編輯區域的高度一致 */
}

.ck-editor__editable_inline {
  min-height: 200px;
  max-height: 400px; /* 如果需要限制高度 */
  height: 200px;
}
</style>

<script>
import { zh } from "vuejs-datepicker/dist/locale";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import benefitCardApi from "@/apis/benefitCard";
import mergeDefaultAndDataModel from "@/utils/mergeDefaultAndDataModel";
import deepGet from "lodash/get";
import { mapGetters } from "vuex";
import collectionApi from "@/apis/collection";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import JsonEditor from "@/components/Dashboard/JsonEditor.vue";
import AppDatePicker from "@/components/AppDatePicker.vue";

export default {
  components: {
    AppDatePicker,
    JsonEditor,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      benefitCard: {
        title: { required },
        order: { required },
      }
    };
  },
  computed: {
    consts() {
      return consts
    },
    ...mapGetters("route", ["routeQuery"]),
  },
  data() {
    const today = new Date(); // 今天
    const tomorrow = new Date(today); // 複製今天的日期物件
    tomorrow.setDate(today.getDate() + 1); // 加 1 天
    const formatDate = (date) => {
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    };
    return {
      zh,
      isEditing: false,
      showLoading: false,
      validationErrors: null,
      step: '',
      benefitCard: {
        title: null,
        is_enabled: true,
        order: 1,
        config: {},
        start_at: formatDate(today),
        end_at: formatDate(tomorrow),
      },
      permissionChecker: new PermissionChecker(),
    }
  },
  mounted() {
    if (this.$route.name === "BenefitCardEdit") {
      this.isEditing = true;
      this.benefitCard.id = this.$route.params.id;
      this.fetchBenefitCard();
      this.navigateToStepFromCurrentRoute();
    } else {
      this.isEditing = false;
      this.showLoading = true;
      this.getMaxOrder();
    }
    this.fetchCollections();
  },
  methods: {
    deepGet,
    mergeDefaultAndDataModel,
    async fetchCollections() {
      try {
        let response = await collectionApi.getCollections({
          with_items: 1,
          is_enabled: 1,
        });
        this.collectionOptions = response.data.data.map((collection) => {
          return {
            value: collection.id,
            text: collection.name + ' 共 ' + collection.items_count + ' 筆資料',
            items: collection.items,
            config: collection.config,
          };
        });
      } catch (error) {
        console.error(error);
      }
    },
    presentValidationErrors(validationKey) {
      return deepGet(this.v$, validationKey + '.$errors', []).map(error => error.$message).join(', ')
    },
    cancel() {
      this.$router.push({ name: 'BenefitCardList', query: this.routeQuery });
    },
    goToStep(step) {
      if (this.step == step) {
        return;
      }

      this.step = step
      if (location.hash !== this.step) {
        // NOTE: 這裡不使用 $router.replace，以避免 page rerender 導致 mounted 或其他 lifecycle hook 的內的 API 重複執行
        const newLocation = new URL(location.href)
        newLocation.hash = this.step
        window.history.replaceState('', '', newLocation.href);
      }
    },
    navigateToStepFromCurrentRoute() {
      const anchor = this.$route.hash;
      if (anchor) {
        this.goToStep(anchor);
      }
    },
    async submit() {
      const result = await this.v$.$validate();
      if (!result) return false;
      try {
        this.showLoading = true;
        // 編輯表單
        if (this.isEditing) {
          await benefitCardApi.updateBenefitCard(this.benefitCard.id, this.benefitCard);
          this.$swal("儲存成功", "", "success");
        }
        // 新增表單
        else {
          let response = await benefitCardApi.createBenefitCard({ ...this.benefitCard });
          this.benefitCard = response.data.data;
          this.$swal("新增成功", "", "success");
          this.isEditing = true;
          this.$router.replace({ name: "BenefitCardEdit", params: { id: response.data.data.id } })
        }

        return true;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存失敗", "error");
        return false;
      } finally {
        this.showLoading = false;
      }
    },
    async fetchBenefitCard() {
      if (! this.benefitCard.id) {
        this.$swal("錯誤", "無法取得讀取 ID", "error");
        return;
      }

      try {
        this.showLoading = true;
        let response = await benefitCardApi.getBenefitCard(this.benefitCard.id);
        this.$set(this, 'benefitCard', response.data.data);
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料錯誤", "error");
        this.cancel();
      } finally {
        this.showLoading = false;
      }
    },
    async getMaxOrder() {
      let response = await benefitCardApi.getMaxOrder();
      this.benefitCard.order = response.data.maxOrder+1;
      this.$set(this, 'benefitCard', this.benefitCard);
      this.showLoading = false;
    },
    async saveFields(fields) {
      try {
        this.showLoading = true;

        this.benefitCard.config.fields = fields;

        await benefitCardApi.updateBenefitCard(this.benefitCard.id, this.benefitCard);
        this.$swal("儲存成功", "", "success");
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存資料錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    checkPermission(permissions) {
      return this.permissionChecker.check(permissions);
    },
  }
}
</script>

<style lang="scss" scoped>
.menu-items > li {
  cursor: pointer;

  &.disabled {
    background-color: #ddd;
    opacity: 0.5;
    pointer-events: none;
  }

  &.has-checkbox {
    &:after {
      content: "\f134";
      font-family: "Material Design Icons";
      font-size: 16px;
    }
  }

  &.has-checkbox.has-checkbox--checked {
    &:after {
      content: "\f133";
      color: green;
    }
  }
}
</style>
